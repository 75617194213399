import React, { useState } from "react";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";

import { useMutation } from "react-query";
import { subscribe } from "../../services";
import styles from "./Email.module.scss";
import "react-toastify/dist/ReactToastify.css";

//images
import EmailIcon from "../../assets/images/email.svg";
import CheckCircleIcon from "../../assets/icons/check_circle (1).png";
import RightArrow from "../../assets/images/right-arrow.svg";

const Email = ({ setShowModal }) => {
  const [email, setEmail] = useState();

  const { mutate, isLoading } = useMutation((payload) => subscribe(payload), {
    onSuccess: (data) => {
      setEmail("");
      toast.success("Email subscription successful", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setShowModal(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message ?? "An error occurred", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    },
  });

  const handleSubmit = () => {
    if (!email) {
      console.log("called");
      toast.error("Email address is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    mutate({ email });
  };
  return (
    <>
      {false ? (
        <div className={styles.email__submitted}>
          {/* <img
            className={styles.email__submitted__icon}
            src={CheckCircleIcon}
          /> */}

          <h3 className={styles.email__submitted__text}>
            Email has been submitted!!
          </h3>
        </div>
      ) : (
        <div className={styles.email}>
          <img src={EmailIcon} alt="" />
          <h3>Email</h3>

          <p>Enter your email below to get started.</p>
          <input
            className={styles.email__form__input}
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            onClick={() => handleSubmit()}
            className={styles.email__submit}
          >
            {isLoading ? (
              <ReactLoading type="spin" width={25} height={25} />
            ) : (
              <>
                <span>Get Started</span>
                <img src={RightArrow} alt="right arrow" />
              </>
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default Email;
