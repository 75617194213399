import React from "react";
import styles from "./SectionFour.module.scss";

//images
import PayoutImage from "../../../assets/images/payout-image.jpg";
//import SectionImg2 from "../../../assets/images/fb-sectionFour-img-2.png";
import PayoutTag from "../../../assets/images/payout-tag.svg";
import RightArrow from "../../../assets/images/right-arrow.svg";

const SectionFour = ({ setShowModal }) => {
  return (
    <div id="payment-link" className={styles.sectionFour}>
      <div className={styles.sectionFour__main}>
        <div className={styles.sectionFour__img}>
          <img
            className={styles.sectionFour__img__main}
            src={PayoutImage}
            alt=""
          />
          {/* <img
            className={styles.sectionFour__img__secondary}
            src={SectionImg2}
            alt="tag"
          /> */}
          <img
            className={styles.sectionFour__img__tag}
            src={PayoutTag}
            alt="tag"
          />
        </div>
        <div className={styles.sectionFour__text}>
          <h6>
            RETAIL & BULK <br /> DISBURSEMENTS
          </h6>
          <h2>Payouts</h2>
          <p>
          Our user-friendly payout solution is useful for cryptocurrency payouts to retail customers. Alternatively, organizations that need to execute bulk cryptocurrency payments to a large number of beneficiaries are not left out. Our collaborative custody solution gurantees the safety of funds while processing your payouts. Now, custody and payout digital currencies just as easily as you do fiat currencies. Step into the future of automated money operations with Knit.
          </p>
          <button onClick={() => setShowModal(true)}>
            <span>See Demo</span>
            <img src={RightArrow} alt="right arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
