import React from "react";
import styles from "./SectionFour.module.scss";

import RegisterIcon from "../../assets/images/register.png";
import UseKnitIcon from "../../assets/images/useknit.png";
import ConnectIcon from "../../assets/images/connect.png";
import ApproveIcon from "../../assets/images/approve.png";
import KycIcon from "../../assets/images/kyc.png";

const SectionFour = () => {
  return (
    <div className={styles.sectionFour}>
      <div className={styles.sectionFour__intro}>
        <h2>How to use Knit</h2>
        <p>
          Begin your journey to financial freedom with our seamless onboarding
          process, all in just five easy steps.
        </p>
      </div>
      <div className={styles.sectionFour__steps}>
        <div className={styles.sectionFour__step}>
          <div className={styles.sectionFour__step__image}>
            <img src={RegisterIcon} alt="register" />
            <div className={styles.sectionFour__step__image__shadow}></div>
          </div>
          <div className={styles.sectionFour__step__content}>
            <h4>Register</h4>
            <p>
              Create your Knit account by providing your email and a strong pin.{" "}
            </p>
          </div>
        </div>

        <div className={styles.sectionFour__step}>
          <div className={styles.sectionFour__step__image}>
            <img src={KycIcon} alt="kyc" />
            <div className={styles.sectionFour__step__image__shadow}></div>
          </div>
          <div className={styles.sectionFour__step__content}>
            <h4>K.Y.C</h4>
            <p>
              Complete our quick KYC verification process to ensure platform
              security.
            </p>
          </div>
        </div>

        <div className={styles.sectionFour__step}>
          <div className={styles.sectionFour__step__image}>
            <img src={ConnectIcon} alt="connect" />
            <div className={styles.sectionFour__step__image__shadow}></div>
          </div>
          <div className={styles.sectionFour__step__content}>
            <h4>Connect</h4>
            <p>Integrate your existing decentralized wallet.</p>
          </div>
        </div>

        <div className={styles.sectionFour__step}>
          <div className={styles.sectionFour__step__image}>
            <img src={ApproveIcon} alt="approve" />
            <div className={styles.sectionFour__step__image__shadow}></div>
          </div>
          <div className={styles.sectionFour__step__content}>
            <h4>Approve</h4>
            <p>
              Create a direct debit authorization with a preferred <b>limit</b> on the
              connected wallet.
            </p>
          </div>
        </div>

        <div className={styles.sectionFour__step}>
          <div className={styles.sectionFour__step__image}>
            <img src={UseKnitIcon} alt="use knit" />
            <div className={styles.sectionFour__step__image__shadow}></div>
          </div>
          <div className={styles.sectionFour__step__content}>
            <h4>Use Knit</h4>
            <p>Start anew- spend, send, and receive with absolute freedom.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFour;
