import React from "react";
import styles from "./SectionThree.module.scss";

//images
import Check from "../../assets/icons/check-mark-circle.png";
import Cross from "../../assets/icons/cross-circle.png";

const SectionThree = () => {
  return (
    <div className={styles.sectionThree}>
      <div className={styles.sectionThree__content}>
        <div className={styles.sectionThree__intro}>
          <h2>
            <b>Knit cards</b> <br /> vs <br /> Other cards
          </h2>
          <p>
            Our cards stand apart for numerous reasons. We invite you to be the
            judge and experience the difference firsthand.
          </p>
        </div>

        <div className={styles.sectionThree__main}>
          <div className={styles.sectionThree__points}>
            <h3 className={styles.sectionThree__points__heading}>Knit</h3>
            <div className={styles.sectionThree__points__content}>
              <p>
                <img src={Check} alt="check" />{" "}
                <span>Integrates blockchain technology.</span>
              </p>
              <p>
                <img src={Check} alt="check" />{" "}
                <span>Supports various digital currencies.</span>
              </p>
              <p>
                <img src={Check} alt="check" />{" "}
                <span>Blockchain integrated security.</span>
              </p>
              <p>
                <img src={Check} alt="check" />{" "}
                <span>Transparent & immutable transactions.</span>
              </p>
              <p>
                <img src={Check} alt="check" />{" "}
                <span>No pre-funding required.</span>
              </p>
            </div>
          </div>

          <div
            className={`${styles.sectionThree__points} ${styles.sectionThree__points__others}`}
          >
            <h3 className={styles.sectionThree__points__heading}>Others</h3>
            <div className={styles.sectionThree__points__content}>
              <p>
                <img src={Cross} alt="cross" />{" "}
                <span>Use conventional centralised systems.</span>
              </p>
              <p>
                <img src={Cross} alt="cross" />{" "}
                <span>Limited to fiat currency transactions.</span>
              </p>
              <p>
                <img src={Cross} alt="cross" />{" "}
                <span>Standard security measures</span>
              </p>

              <p>
                <img src={Cross} alt="cross" />{" "}
                <span>Intermediary verification with transactions.</span>
              </p>

              <p>
                <img src={Cross} alt="cross" />{" "}
                <span> Pre-funding required</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
