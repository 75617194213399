import { Http } from "../utils/http";

export const userLogin = (payload) => Http.post(`/auth/login`, payload);

export const userLoginFinalize = (payload) =>
  Http.post(`/auth/login/finalize`, payload);

export const userDetails = () => Http.get(`/auth/profile`);

export const createTransactionInvoice = (payload) =>
  Http.post("/transactions", payload);

export const getTransactions = () => Http.get("/transactions");

export const getTransaction = (id) => Http.get(`/transactions/${id}`);

export const getCoinValueForLocalCurrency = (
  local_currency,
  local_amount,
  coin
) =>
  Http.get(`/transactions/${local_currency}/${local_amount}/convert/${coin}`);

export const subscribe = (payload) =>
  Http.post(`/webiste-subscribe/website-subscribe`, payload);
