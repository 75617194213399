import React from "react";
import { XCircle } from "phosphor-react";
import styles from "./Modal.module.scss";

//images
import ExitIcon from "../../assets/icons/exit.svg";

const Modal = ({ children, setShowModal }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modal__main}>
        <div
          className={styles.modal__cancel}
          onClick={() => setShowModal(false)}
        >
          {/* <XCircle size={32} color="#fff" weight="fill" /> */}
          <img src={ExitIcon} alt="exit icon" />
        </div>
        <div className={styles.modal__body}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
