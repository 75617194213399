import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import useOutOfFocus from "../../Hooks/useOutOfFocus";
import styles from "./ForBusinesses.module.scss";

//images
import Logo from "../../assets/images/logo.svg";
import Globe from "../../assets/images/globe-2.png";
import RightArrow from "../../assets/images/right-arrow.svg";
import Menu from "../../assets/images/menu-alt (2).svg";
import Phone from "../../assets/images/phone.jpg";

//bg-coins
import CoinGroup11 from "../../assets/bg-coins/group1-1.png";
import CoinGroup12 from "../../assets/bg-coins/group1-2.png";
import CoinGroup21 from "../../assets/bg-coins/group2-1.png";
import CoinGroup22 from "../../assets/bg-coins/group2-2.png";
import CoinGroup31 from "../../assets/bg-coins/group3-1.png";
import CoinGroup32 from "../../assets/bg-coins/group3-2.png";
import FutureLine from "../../assets/images/future-line.svg";

//components
import SectionOne from "../ForBusinessesSections/SectionOne/SectionOne";
import SectionTwo from "../ForBusinessesSections/SectionTwo/SectionTwo";
import SectionThree from "../ForBusinessesSections/SectionThree/SectionThree";
import SectionFour from "../ForBusinessesSections/SectionFour/SectionFour";
import SectionFive from "../ForBusinessesSections/SectionThree/SectionThree";
import Footer from "../../Sections/Footer/Footer";
import ModalWrapper from "../../components/Modal/Modal";
import Email from "../../components/Email/Email";

const ForBusinesses = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const { isOutOfFocus, setIsOutOfFocus } = useOutOfFocus(menuRef);

  useEffect(() => {
    if (isOutOfFocus) {
      setIsOutOfFocus(false);
      setShowMenu(false);
    }
  }, [isOutOfFocus, setIsOutOfFocus]);

  return (
    <div className={styles.forBusinesses}>
      <Helmet>
        <title>KNIT | Blockchain Solutions Simplified</title>
        <meta
          name="description"
          content="Where blockchain and traditional payments meet"
        />
      </Helmet>
      <div className={styles.forBusinesses__bgCoins__groupOne}>
        <img src={CoinGroup11} alt="bg-coin" />
        <img src={CoinGroup12} alt="bg-coin" />
      </div>
      <div className={styles.forBusinesses__nav}>
        <img src={Logo} alt="knit logo" />

        {/* <div
          ref={menuRef}
          onClick={() => setShowMenu(!showMenu)}
          className={styles.forBusinesses__mobileNav__menu}
        >
          <img
            className={styles.forBusinesses__mobileNav__menu__icon}
            alt="menu icon"
            src={Menu}
          />

          {showMenu && (
            <div className={styles.forBusinesses__mobileNav__menu__items}>
              <div
                onClick={() => navigate("/")}
                className={styles.forBusinesses__mobileNav__menu__item}
              >
                <p>For Individuals</p>
              </div>
              <div className={styles.forBusinesses__mobileNav__menu__item}>
                <p>For Businesses</p>
              </div>
            </div>
          )}
        </div> */}

        {/* <div className={styles.forBusinesses__nav__items}>
          <div
            onClick={() => navigate("/")}
            className={`${styles.forBusinesses__nav__item}`}
          >
            <p>For Individuals</p>
          </div>
          <div
            className={`${styles.forBusinesses__nav__item} ${styles.forBusinesses__nav__item__active}`}
          >
            <p>For Businesses</p>
          </div>
        </div> */}
      </div>

      <div className={styles.forBusinesses__intro}>
        <div className={styles.forBusinesses__intro__main}>
          <h2 className={styles.forBusinesses__intro__heading}>
            Blockchain infrastructure <br /> for now & the{" "}
            <b>
              future
              <img
                src={FutureLine}
                className={styles.forBusinesses__intro__heading__line}
                alt="bg-coin"
              />
            </b>
          </h2>
          <button
            onClick={() => setShowModal(true)}
            className={styles.forBusinesses__intro__login}
          >
            <span>Get Started</span>
            <img src={RightArrow} alt="right arrow" />
          </button>
        </div>

        <div className={styles.forBusinesses__bgCoins__groupTwo}>
          <img src={CoinGroup21} alt="bg-coin" />
          <img src={CoinGroup22} alt="bg-coin" />
        </div>

        <div className={styles.forBusinesses__bgCoins__groupThree}>
          <img src={CoinGroup31} alt="bg-coin" />
          <img src={CoinGroup32} alt="bg-coin" />
        </div>
      </div>
      <SectionOne />
      <SectionTwo setShowModal={setShowModal} />
      <SectionThree setShowModal={setShowModal} />
      <SectionFour setShowModal={setShowModal} />
     <Footer />
      {showModal && (
        <ModalWrapper showModal={showModal} setShowModal={setShowModal}>
          <Email setShowModal={setShowModal} />
        </ModalWrapper>
      )}
    </div>
  );
};

export default ForBusinesses;
