import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useOutOfFocus from "../../Hooks/useOutOfFocus";
import styles from "./LandingPage.module.scss";

//images
import IntroImage1 from "../../assets/images/intro-img-1.png";
import IntroImage2 from "../../assets/images/intro-img-2.png";
import IntroImage3 from "../../assets/images/intro-img-3.png";
import Logo from "../../assets/images/logo.svg";
import Globe from "../../assets/images/globe-2.png";
import RightArrow from "../../assets/images/right-arrow.svg";
import Menu from "../../assets/images/menu-alt (2).svg";

//section two
import PlusIcon from "../../assets/icons/plus (1).svg";
import BrandOne from "../../assets/images/image 7.png";
import BrandTwo from "../../assets/images/image 9.png";
import BrandThree from "../../assets/images/image 4 (1).png";
import BrandFour from "../../assets/images/image 5.png";
import BrandFive from "../../assets/images/image 3.png";
import BrandSix from "../../assets/images/Group 55510.png";
import BrandSeven from "../../assets/images/Group 55505.png";
import KnitCards from "../../assets/images/Group 55526.png";

//components
import SectionThree from "../../Sections/SectionThree/SectionThree";
import SectionFour from "../../Sections/SectionFour/SectionFour";
import SectionFive from "../../Sections/SectionFive/SectionFive";
import Footer from "../../Sections/Footer/Footer";

//bg-coins
import CoinGroup11 from "../../assets/bg-coins/group1-1.png";
import CoinGroup12 from "../../assets/bg-coins/group1-2-variant.png";
import CoinGroup21 from "../../assets/bg-coins/group2-1.png";
import CoinGroup22 from "../../assets/bg-coins/group2-2.png";
import CoinGroup31 from "../../assets/bg-coins/group3-1.png";
import CoinGroup32 from "../../assets/bg-coins/group3-2.png";

const LandingPage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const { isOutOfFocus, setIsOutOfFocus } = useOutOfFocus(menuRef);

  useEffect(() => {
    if (isOutOfFocus) {
      setIsOutOfFocus(false);
      setShowMenu(false);
    }
  }, [isOutOfFocus, setIsOutOfFocus]);

  return (
    <div className={styles.home}>
      <div className={styles.home__bgCoins__groupTwo}>
        <img src={CoinGroup21} alt="bg-coin" />
        <img src={CoinGroup22} alt="bg-coin" />
      </div>
      <div className={styles.home__nav}>
        <img src={Logo} alt="knit logo" />

        <div
          ref={menuRef}
          onClick={() => setShowMenu(!showMenu)}
          className={styles.home__mobileNav__menu}
        >
          <img
            className={styles.home__mobileNav__menu__icon}
            alt="menu icon"
            src={Menu}
          />

          {showMenu && (
            <div className={styles.home__mobileNav__menu__items}>
              <div className={styles.home__mobileNav__menu__item}>
                <p>For Individuals</p>
              </div>

              <div
                onClick={() => navigate("/for-businesses")}
                className={styles.home__mobileNav__menu__item}
              >
                <p>For Businesses</p>
              </div>
            </div>
          )}
        </div>

        <div className={styles.home__nav__items}>
          <div
            className={`${styles.home__nav__item} ${styles.home__nav__item__active}`}
          >
            <p>For Individuals</p>
          </div>
          <div
            onClick={() => navigate("/for-businesses")}
            className={styles.home__nav__item}
          >
            <p>For Businesses</p>
          </div>
        </div>
      </div>

      <div className={styles.home__intro}>
        <img
          className={styles.home__intro__card1}
          src={IntroImage1}
          alt="card"
        />
        <img
          className={styles.home__intro__card2}
          src={IntroImage2}
          alt="card"
        />
        <div className={styles.home__intro__main}>
          <div className={styles.home__bgCoins__groupThree}>
            <img src={CoinGroup31} alt="bg-coin" />
            <img src={CoinGroup32} alt="bg-coin" />
          </div>

          <div className={styles.home__bgCoins__groupOne}>
            <img src={CoinGroup11} alt="bg-coin" />
            <img src={CoinGroup12} alt="bg-coin" />
          </div>

          <h2 className={styles.home__intro__heading}>
            Non-custodial banking: Be your own bank
          </h2>
          <p className={styles.home__intro__desc}>
            Get your virtual cards to spend directly from your decentralised
            wallet in real time. No pre-funding required.
          </p>
          <button
            onClick={() =>
              (window.location.href = "https://app.useknit.io/dashboard")
            }
            className={styles.home__intro__login}
          >
            <span>Continue to Login</span>
            <img src={RightArrow} alt="right arrow" />
          </button>
        </div>

        <img
          className={styles.home__intro__card3}
          src={IntroImage3}
          alt="card"
        />
      </div>

      <div className={styles.home__sectionTwo}>
        <div className={styles.home__sectionTwo__intro}>
          <h2 className={styles.home__sectionTwo__heading}>
            Self-custody + Knit: Window to the world
          </h2>
          <p className={styles.home__sectionTwo__desc}>
            Integrate our virtual cards with Apple Pay, Google Pay, or Samsung
            Pay for effortless crypto spending online or in-person, anytime,
            anywhere.
          </p>
        </div>

        <img
          src={PlusIcon}
          alt="add"
          className={styles.home__sectionTwo__add}
        />

        <div className={styles.home__sectionTwo__content}>
          <div className={styles.home__sectionTwo__content__brands}>
            <img
              src={BrandOne}
              className={styles.home__sectionTwo__content__brand1}
              alt="brand"
            />
            <img
              src={BrandTwo}
              className={styles.home__sectionTwo__content__brand2}
              alt="brand"
            />
            <img
              src={BrandThree}
              className={styles.home__sectionTwo__content__brand3}
              alt="brand"
            />
            <img
              src={BrandFour}
              className={styles.home__sectionTwo__content__brand4}
              alt="brand"
            />
            <img
              src={BrandFive}
              className={styles.home__sectionTwo__content__brand5}
              alt="brand"
            />
            <img
              src={BrandSix}
              className={styles.home__sectionTwo__content__brand6}
              alt="brand"
            />
            <img
              src={BrandSeven}
              className={styles.home__sectionTwo__content__brand7}
              alt="brand"
            />
          </div>

          <div className={styles.home__sectionTwo__content__cards}>
            <img src={KnitCards} />
          </div>
          <img
            src={Globe}
            className={styles.home__sectionTwo__globe}
            alt="globe"
          />
        </div>
      </div>

      <SectionThree />
      <SectionFour />
      <SectionFive />
      <Footer />
    </div>
  );
};

export default LandingPage;
