import React from "react";
import styles from "./SectionThree.module.scss";

//images
import DirectDebitImage from "../../../assets/images/direct-debit-image.png";
import DirectDebitScreenshot from "../../../assets/images/direct-debit-screenshot.png";
import DirectDebitTag from "../../../assets/images/direct-debit-tag.svg";
import RightArrow from "../../../assets/images/right-arrow.svg";

const SectionThree = ({ setShowModal }) => {
  return (
    <div id="direct-debit" className={styles.sectionThree}>
      <div className={styles.sectionThree__main}>
        <div className={styles.sectionThree__text}>
          <h6>
            RECURRENT BILLS <br />& SUBSCRIPTIONS
          </h6>
          <h2>Direct Debits</h2>
          <p>
            Ensure your crypto-savvy customers are not left out. Our direct debit offering enables organizations to automatically collect recurrent payments (fixed or variable) from customers by setting up direct debit mandates on their decentralised cryptocurrency wallets. Knit ensures you offer the best user experience to your customers.
          </p>
          <button onClick={() => setShowModal(true)}>
            <span>See Demo</span>
            <img src={RightArrow} alt="right arrow" />
          </button>
        </div>

        <div className={styles.sectionThree__img}>
          <img
            className={styles.sectionThree__img__main}
            src={DirectDebitImage}
            alt=""
          />
          <img
            className={styles.sectionThree__img__secondary}
            src={DirectDebitScreenshot}
            alt="screenshot"
          />
          <img
            className={styles.sectionThree__img__tag}
            src={DirectDebitTag}
            alt="tag"
          />
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
