import React from "react";
import styles from "./SectionTwo.module.scss";

//images
import SectionImg from "../../../assets/images/fb-sectionTwo-img.jpg";
import SectionTag from "../../../assets/images/fb-sectionTwo-tag.svg";
import RightArrow from "../../../assets/images/right-arrow.svg";

const SectionTwo = ({ setShowModal }) => {
  return (
    <div id="payment-gateway" className={styles.sectionTwo}>
      <div className={styles.sectionTwo__main}>
        <div className={styles.sectionTwo__img}>
          <img
            className={styles.sectionTwo__img__main}
            src={SectionImg}
            alt=""
          />
          <img
            className={styles.sectionTwo__img__tag}
            src={SectionTag}
            alt="tag"
          />
        </div>
        <div className={styles.sectionTwo__text}>
          <h6>SINGLE-USE & <br /> STATIC WALLETS</h6>
          <h2>Collections</h2>
          <p>
            Welcome to the next level of digital transactions. Our cryptocurrency collections service allows you to accept digital currencies smartly. Whether you’re running a payment processor needing single-use wallets or remittance company requiring static wallets, with Knit, you can offer your customers the freedom to pay in their preferred cryptocurrency.
          </p>
          <button onClick={() => setShowModal(true)}>
            <span>See Demo</span>
            <img src={RightArrow} alt="right arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
