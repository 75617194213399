import React from "react";
import { Route, Routes as Switch, Navigate } from "react-router-dom";

// pages
import LandingPage from "../pages/LandingPage/LandingPage";
import ForBusinesses from "../pages/ForBusinesses/ForBusinesses";


const Routes = () => {
  return (
    <> 
      <Switch>
        <Route path="/" element={<ForBusinesses />} />
        <Route path="/for-individuals" element={<LandingPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Switch>
    </>
  );
};

export default Routes;
