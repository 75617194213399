import React from "react";
import styles from "./SectionOne.module.scss";

//images
import CryptoIllustration from "../../../assets/images/blockchain-solutions-simplified.png";

const SectionOne = () => {
  return (
    <div className={styles.sectionOne}>
      <div className={styles.sectionOne__main}>
        <div className={styles.sectionOne__text}>
          <h2>Blockchain solutions simplified</h2>
          <p>
            Knit’s APIs are designed for banks, financial service providers and
            cypto-friendly businesses, offering comprehensive collections,
            custody and disbursement services. We’re leapfrogging your
            organization into the future. Accept, as well as payout
            cryptocurrencies from, and to your customers, with exceptional ease.
          </p>
        </div>
        <div className={styles.sectionOne__image}>
          <img src={CryptoIllustration} alt="crypto illustration" />
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
