import React from "react";
import styles from "./SectionFive.module.scss";

import Send from "../../assets/images/send.svg";

const SectionFive = () => {
  return (
    <div className={styles.sectionFive}>
      <div className={styles.sectionFive__main}>
        <div className={styles.sectionFive__image}>
          <img src={Send} alt="send" />
        </div>
        <div className={styles.sectionFive__content}>
          <h2>Coming Soon</h2>
          <ul>
            <li>
              Easily transfer funds from your self-custody wallet to fiat bank
              accounts worldwide.
            </li>
            <li>
              Receive money in multiple currencies such as USD, GBP, EUR, CAD,
              AED and have it automatically deposited in your self-custody
              wallet in crypto.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
